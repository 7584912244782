import React, { useEffect, useState, useContext, useMemo } from "react";
import {
  Row,
  Col,
  Select,
  Input,
  Button,
  notification,
  InputNumber,
  Switch,
  Skeleton,
} from "antd";
import { conduktClient } from "clients/condukt";
import { AppContext } from "AppContextProvider";
import Dropdown from "./dropdown";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import useHasPermission, { PERMISSIONS } from "../../clients/useHasPermission";

import config from "../../config";

const countries = ["GB", "FR", "DE", "IT"];

const inputPlaceholders = {
    "GB": 'Company name or number (e.g. "Hjem Kensington" or "10967711")',
    "FR": 'Company name, SIREN or SIRET (e.g. "Deux Magots" or "334365947")',
    "DE": 'Company name or register number (e.g. "Weinerei am Weinberg" or "HRA 54405 B")',
    "IT": 'Partita IVA number (e.g. for Felice Milano SRL type "16857631002")',
}

function Request() {
  const [country, setCountry] = useState(null);
  const [searchOptions, setSearchOptions] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [isCreatingOrder, setIsCreatingOrder] = useState(false);
  const [searchRegNo, setSearchRegNo] = useState(null);
  const [query, setQuery] = useState("");
  const { setSelectedOrderId, setTabOpen, jwt } = useContext(AppContext);
  const [ownerShareThreshold, setOwnerShareThreshold] = useState(25);
  const [votingThreshold, setVotingThreshold] = useState(25);
  const [significantControl, setSignificantControl] = useState(true);

  const uboPermission = useHasPermission(PERMISSIONS.UBO);
  const unitedKingdomPermission = useHasPermission(PERMISSIONS.UNITED_KINGDOM);
  const francePermission = useHasPermission(PERMISSIONS.FRANCE);
  const germanyPermission = useHasPermission(PERMISSIONS.GERMANY);
  const italyPermission = useHasPermission(PERMISSIONS.ITALY);

  const permissionsToCountries = {
    GB: unitedKingdomPermission,
    FR: francePermission,
    DE: germanyPermission,
    IT: italyPermission,
  };

  const countryOptions = useMemo(() => {
    const options = [];
    for (const [country, permission] of Object.entries(
      permissionsToCountries
    )) {
      if (permission) {
        options.push({ value: country, label: country });
      }
    }

    return options;
  }, [jwt]);

  useEffect(() => {
    if (countryOptions.length >= 1) {
      setCountry(countryOptions[0].value);
    }
  }, [countryOptions]);

  useEffect(() => {
    setSearchOptions(null);
  }, [country]);

  useEffect(() => {
    if (searchOptions?.[0]?.registration_number)
      setSearchRegNo(searchOptions?.[0]?.registration_number);
  }, [searchOptions]);

  const onSearch = async () => {
    setIsSearching(true);
    setSearchOptions([]);

    try {
      const res = await conduktClient.search(country, query);
      const searchOptions = res?.results || [];
      setSearchOptions(searchOptions);
    } catch (error) {
      notification.error({ message: "Unable to search" });
    }

    setIsSearching(false);
  };

  const onCreateOrder = async () => {
    setIsCreatingOrder(true);
    try {
      const bpoOrder = await conduktClient.createBusinessProfileOrder({
        country,
        registration_number: searchRegNo,
        beneficial_owner_shares_threshold: ownerShareThreshold,
        beneficial_owner_voting_rights_threshold: votingThreshold,
        beneficial_owner_has_other_significant_control: significantControl,
      });

      if (uboPermission) {
        const uboOrder = await conduktClient.createUBOOrder({
          country,
          registration_number: searchRegNo,
        });

        setSelectedOrderId({
          bpoOrderId: bpoOrder?.id,
          uboOrderId: uboOrder?.id,
        });
      } else {
        setSelectedOrderId({
          bpoOrderId: bpoOrder?.id,
          uboOrderId: null,
        });
      }
      setTabOpen("businessProfile");
    } catch (error) {
      notification.error({ message: "Unable to create order" });
    }
    setIsCreatingOrder(false);
  };

  const selectOptions = searchOptions?.map((company) => ({
    value: company?.registration_number,
    label: `${company?.name} (${company?.registration_number} ${company?.country})`,
  }));

  if (!country) {
    return <Skeleton paragraph={{ rows: 20 }} active />;
  }

  return (
    <>
      <Row gutter={10}>
        <Col span={4}>
          <p>Country</p>

          <Select
            size="large"
            style={{ width: "100%" }}
            value={country}
            onChange={(country) => setCountry(country)}
            label="Select country"
            options={countryOptions}
          />
        </Col>

        <Col span={16}>
          <p>Search all</p>
          <Input
            value={query}
            onChange={(e) => {
              setQuery(e.target.value);
            }}
            placeholder={inputPlaceholders[country]}
            size="large"
            data-testid="country-search"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onSearch();
              }
            }}
          />

          <Col span={24}>
            <Dropdown title="Advanced Order Options">
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                  alignItems: "flex-start",
                  gap: 5,
                }}
              >
                <>
                  Beneficial Owner Share Threshold
                  <InputNumber
                    value={ownerShareThreshold}
                    onChange={setOwnerShareThreshold}
                    addonAfter={"%"}
                    min="0"
                    max="25"
                    step="0.01"
                    stringMode
                  />
                </>
                Beneficial Owner Voting Threshold
                <InputNumber
                  value={votingThreshold}
                  onChange={setVotingThreshold}
                  addonAfter={"%"}
                  min="0"
                  max="25"
                  step="0.01"
                  stringMode
                />
                Beneficial Owners with other types of significant control
                <Switch
                  checkedChildren="Show"
                  unCheckedChildren="Hide"
                  value={significantControl}
                  onChange={setSignificantControl}
                />
              </div>
            </Dropdown>
          </Col>
        </Col>
        <Col span={4}>
          <p style={{ visibility: "hidden" }}>Search</p>
          <Button
            type="primary"
            disabled={!query || isSearching}
            onClick={onSearch}
            size="large"
            data-testid="country-search-button"
          >
            {isSearching ? <LoadingOutlined /> : <SearchOutlined />}
          </Button>
        </Col>
      </Row>

      <br />
      {searchOptions !== null &&
        !isSearching &&
        (searchOptions?.length ? (
          <Row>
            <Col span={4}></Col>
            <Col span={20}>
              <p>Select Company</p>
              <Select
                size="large"
                style={{ width: "100%" }}
                placeholder="Select Company"
                optionFilterProp="children"
                onChange={(searchRegNo) => setSearchRegNo(searchRegNo)}
                options={selectOptions}
                label="Select company"
                defaultValue={selectOptions?.[0]}
              />
              <br />
              <br />

              <div className="flex space-x-4 items-center">
                <Button
                  disabled={true}
                  size="large"
                  type="primary"
                  onClick={onCreateOrder}
                >
                  {isCreatingOrder ? "Creating order..." : "Submit"}
                </Button>

                <div>
                  Ordering disabled. Please use the{" "}
                  <a
                    className="underline"
                    href={config.NEW_PORTAL_URL}
                    target="blank"
                  >
                    new portal
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        ) : (
          <div className="centered">
            <p style={{ color: "#ceff00" }}>No search result</p>
          </div>
        ))}
    </>
  );
}

export default Request;
